import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

function QuickSearchToolbar(props) {
  const params = useParams();
  const { user, permissions } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    `/system/companies/${params?.companyId}/invoicesExport`,
    () => {
      return axios.get(`/system/companies/${params?.companyId}/invoices`, {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function CompanyPrepaidBill() {
  const params = useParams();
  const { user, permissions } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    [
      `/system/companies/${params?.companyId}/invoices`,
      ,
      page,
      sortModel,
      filterValue,
      searchText,
      perPage,
    ],
    () => {
      return axios.get(`/system/companies/${params?.companyId}/invoices`, {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: ["item", "status", "number", "invoice_type"],
                    }),
                  },
                ]
              : []),

            // {
            //   columnField: "group_id",
            //   operatorValue: "=",
            //   value: params?.companyId,
            // },
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.errorMessage ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: " Generated time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 410,
                  field: "number",
                  headerName: "Invoice number",
                },
                {
                  width: 160,
                  field: "item",
                  headerName: "Item",
                },
                {
                  width: 120,
                  field: "due_balance",
                  headerName: "Due balance",
                  type: "number",
                },
                {
                  field: "quantity",
                  headerName: "Quantity",
                  type: "number",
                },
                {
                  width: 190,
                  field: "invoice_type",
                  headerName: "Invoice_type",
                },
                {
                  width: 160,
                  field: "status",
                  headerName: "Status",
                  headerAlign: "center",
                  align: "center",
                  renderCell: (params) => {
                    return (
                      <Alert
                        icon={false}
                        severity=""
                        sx={{
                          color: `${
                            params?.value === "OVERDUE"
                              ? "#C11616"
                              : params?.value === "APPROVED"
                              ? "#14BB0F"
                              : params?.value === "PENDING"
                              ? "#FFBB00"
                              : "#000"
                          }`,
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {params?.value?.toLowerCase()}
                        </Typography>
                      </Alert>
                    );
                  },
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              page={page}
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {openAdd && (
        <AddCompanyBalanceForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const AddCompanyBalanceForm = ({ loading, refetch, openAdd, setOpenAdd }) => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/system/companies/${params?.companyId}/topup_balance`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added balance!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Company Balance
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: "Amount is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    type="number"
                    label="Amount"
                    helperText={errors?.amount ? errors?.amount?.message : ""}
                    error={errors?.amount}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                rules={{
                  required: "Note is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Note"
                    helperText={errors?.note ? errors?.note?.message : ""}
                    error={errors?.note}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            return mutate(data);
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
