/* eslint-disable no-unused-vars */
import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";
import { QueryClient, useMutation, useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { Close, Lightbulb } from "@mui/icons-material";
import moment from "moment";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { timeFormatter } from "../../utils/dataFornat";
import { access } from "../../utils/access";
import CustomPoliLine from "./map-containers";

function DialogBox(props) {
  return (
    <Dialog open={props.open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "500px",
          height: "500px",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: -5,
          }}
        >
          <DialogTitle>{props.title}</DialogTitle>
          <IconButton
            sx={{
              mr: 1,
              color: "#000",
            }}
            onClick={() => props.onClose()}
          >
            <Close />
          </IconButton>
        </Box>

        <Box>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TextField
              fullWidth
              multiline
              label="Reason"
              variant="outlined"
              placeholder="enter reason "
              onChange={(e) => {
                props.setReason(e.target.value);
              }}
              sx={{
                mb: 9,
                width: "400px",
              }}
            />

            <Button
              disabled={props.loading}
              sx={{
                backgroundColor: "#E11212",
                color: "#fff",
                px: 3,
                py: 1,
                borderRadius: "10px",
                textTransform: "none",
                ":hover": {
                  backgroundColor: "#E11212",
                },
              }}
              onClick={() => props.actionToTake()}
            >
              {props.btnText}
            </Button>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}
const TripDetails = () => {
  const params = useParams();
  const { user } = useAuth();
  const [openEnd, setOpenEnd] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [polyline, setPolylione] = React.useState("");
  // const { state } = useLocation();
  // const { passenger_phone_number } = state;
  // console.log(" from router ", passenger_phone_number);
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "trip-details",
    () => {
      return axios.get(`/system/trips/${params.tripID}`, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: access(["get street pickup trips"]),
      refetchOnMount: access(["get street pickup trips"]),
      enabled: false,
    }
  );
  let passenger_phone_number = data?.data?.data?.passenger_phone_number;
  useEffect(() => {
    if (access(["get street pickup trips"])) {
      refetch();
    }
    // console.log("refetch", data?.data?.data?.poly_line);
  }, []);

  useEffect(() => {
    isSuccess && setPolylione(data?.data?.data?.poly_line);
    // console.log("polyline", polyline);
    return () => {
      setPolylione("");
    };
  }, [data?.data?.data?.poly_line, isSuccess, polyline]);
  const {
    isLoading: loadingPassengerInfo,
    isError: passengerError,
    data: passengerData,
    error: passenger,
    isSuccess: passengerSuccess,
    refetch: passengerRefetch,
  } = useQuery(
    "passenger-detail",
    () => {
      return axios.get(
        `/system/trips/passenger/all/${passenger_phone_number}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: access(["get street pickup passenger info"]),
      refetchOnMount: access(["get street pickup passenger info"]),
      enabled: false,
    }
  );

  const {
    mutate: endTrip,
    isLoading: endTripLoading,
    error: endTripError,
    data: endTripData,
    isSuccess: endTripSuccess,
  } = useMutation(({ id, reason }) => {
    return axios.patch(
      `/system/trips/end`,
      {
        id,
        reason,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });
  const {
    mutate: cancelTrip,
    isLoading: cancelTripLoading,
    error: cancelTripError,
    data: cancelTripData,
    isSuccess: cancelTripSuccess,
  } = useMutation(({ trip_id, reason }) => {
    return axios.patch(
      `/system/trips/cancel`,
      {
        trip_id,
        reason,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  useEffect(() => {
    if (isSuccess && access(["get street pickup passenger info"])) {
      passengerRefetch();
    }
    if (passenger_phone_number !== data?.data?.data?.passenger_phone_number) {
      passengerRefetch();
    }
  }, [data?.data?.data?.passenger_phone_number, passenger_phone_number]);

  useEffect(() => {
    if (endTripSuccess) {
      enqueueSnackbar("Succesfully ended the trip", {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch() && setOpenEnd(false);
    }
    if (endTripError) {
      enqueueSnackbar("Error ending the trip", {
        variant: "error",
        autoHideDuration: 2000,
      });
      setOpenEnd(false);
    }
  }, [endTripSuccess, endTripError]);

  useEffect(() => {
    if (cancelTripSuccess) {
      enqueueSnackbar("Succesfully cancelled   the trip", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setOpenCancel(false) && refetch();
    }
    if (cancelTripError) {
      enqueueSnackbar("Error cancelling the trip", {
        variant: "error",
        autoHideDuration: 2000,
      });
      setOpenCancel(false);
    }
  }, [cancelTripSuccess, cancelTripError]);

  let driver_full_name =
    data?.data?.data?.driver?.first_name +
    " " +
    data?.data?.data?.driver?.middle_name +
    " " +
    data?.data?.data?.driver?.last_name;

  function formatedDecimal(number) {
    return (Math.round(number * 100) / 100)?.toFixed(2);
  }

  return (
    <>
      <DialogBox
        open={openEnd}
        loading={endTripLoading}
        setOpen={setOpenEnd}
        setReason={setReason}
        onClose={() => setOpenEnd(false)}
        title={"Please enter reason to end the trip"}
        btnText={"End Trip"}
        actionToTake={() => {
          endTrip({
            id: params.tripID,
            reason,
          });
          if (endTripSuccess) {
            setOpenEnd(false) && refetch();
          }
        }}
      />
      <DialogBox
        open={openCancel}
        setOpen={setOpenCancel}
        setReason={setReason}
        loading={cancelTripLoading}
        title={"Please enter reason to cancel the trip"}
        btnText={"Cancel Trip"}
        onClose={() => {
          setOpenCancel(false);
        }}
        actionToTake={() => {
          cancelTrip({
            trip_id: params.tripID,
            reason: reason,
          });
          if (cancelTripSuccess) {
            setOpenCancel(false) && refetch();
          }
        }}
      />
      <Grid
        container
        component={Paper}
        sx={{
          height: "100%",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "white",
          mt: 1,
          flexDirection: {
            lg: "row",
            md: "column-reverse",
            sm: "column-reverse",
            xs: "column-reverse",
          },
        }}
      >
        {access(["get street pickup passenger info"]) && (
          <Grid
            aria-label="Time Line"
            item
            lg={4}
            md={12}
            sm={12}
            xs={12}
            marginBottom="30px"
            sx={{
              mt: 0,
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                animation="pulse"
                width={"100%"}
                height={"100%"}
              />
            ) : (
              <Timeline
                position="alternate"
                aria-label="Time Line component"
                sx={{
                  mt: 0,
                  p: 0,

                  ml: 1,
                  backgroundColor: "#F5F5F5",
                  height: "100%",
                  minWidth: 350,
                }}
              >
                {passengerData?.data?.data?.map((item, index) => {
                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent
                        sx={{
                          // alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          p: 0,
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={item ? item.image : ""}
                          alt="passenger"
                          onClick={() => {
                            window.open(
                              item.image,
                              "popup",
                              "width=800,height=800"
                            );
                          }}
                          sx={{
                            width: "100px",
                            cursor: "pointer",
                            height: "100px",
                            borderRadius: "10%",
                            mt: 4,

                            border:
                              item === passengerData?.data?.data?.[0]
                                ? "3px solid #FFDC00"
                                : " ",
                          }}
                        />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{
                            backgroundColor: "text.secondary",
                            py: 2,
                            margin: "auto 0",
                          }}
                        />
                        <TimelineDot
                          sx={{
                            backgroundColor:
                              item === passengerData?.data?.data?.[0]
                                ? "#FFDC00"
                                : "text.primary",
                            p: item === passengerData?.data?.data?.[0] ? 1 : "",
                          }}
                        />
                        <TimelineConnector
                          sx={{
                            backgroundColor: "text.secondary",
                            m: "auto",
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          p: 0,
                          my: 4,
                        }}
                      >
                        <Box sx={{ mx: 3 }}>
                          <Typography>
                            {moment(item?.date).format("MM-DD-YY")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "text.secondary",
                            }}
                          >
                            Taken by
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.trip?.driver?.first_name +
                              " " +
                              item?.trip?.driver?.middle_name +
                              " " +
                              item?.trip?.driver?.last_name}
                          </Typography>
                          <Typography>
                            {item?.trip?.driver?.phone || ""}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            )}
          </Grid>
        )}
        {access(["get street pickup trips"]) && (
          <Grid
            item
            aria-label="Trip detail "
            lg={8}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              backgroundColor: "background.paper",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              arai-label="button box"
              sx={{
                display: "flex",
                alignSelf: "flex-end ",
                alignItems: "center",
                m: 3,
                mr: 7,
                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 4,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: "gray",
                  }}
                >
                  Status :
                </Typography>

                <Alert
                  severity=""
                  icon={false}
                  sx={{
                    fontSize: "16px",
                    borderRadius: "10px",
                    color: `${
                      data?.data?.data?.status === "STARTED"
                        ? "#FFDC00"
                        : data?.data?.data?.status === "ENDED"
                        ? "#16C138"
                        : data?.data?.data?.status === "CANCELLED"
                        ? "#E11212"
                        : data?.data?.data?.status === "CREATED"
                        ? "#000000"
                        : "text.primary"
                    }`,
                    fontWeight: "bold",
                  }}
                >
                  {" " + isSuccess ? data?.data?.data?.status : ""}
                </Alert>
              </Box>
              {data?.data?.data?.status === ("ENDED" || "CANCELLED") && (
                <Box
                  aria-label="place holder box"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 5,
                    px: 5,
                  }}
                />
              )}

              {data?.data?.data?.status === "STARTED" &&
                access(["end street pickup trip"]) && (
                  <Box
                    aria-label="action box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ml: 3,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: "gray",
                        backgroundColor: "inherit",
                      }}
                    >
                      action
                    </Typography>
                    <Box>
                      <Button
                        aria-label="action box"
                        variant="contained"
                        sx={{
                          backgroundColor: "#E11212",
                          color: "#fff",
                          textTransform: "none",
                          // px: 3,
                          py: 1,
                          mr: 2,
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          setOpenCancel(true);
                        }}
                      >
                        Cancel Trip
                      </Button>
                      <Button
                        aria-label="action box"
                        variant="contained"
                        sx={{
                          backgroundColor: "#E11212",
                          color: "#fff",
                          px: 3,
                          py: 1,
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                        onClick={() => {
                          setOpenEnd(true);
                        }}
                      >
                        End Trip
                      </Button>
                    </Box>
                  </Box>
                )}
              {data?.data?.data?.status === "CREATED" &&
                access(["cancel street pickup trip"]) && (
                  <Box
                    aria-label="action box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ml: 5,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: "gray",
                        backgroundColor: "inherit",
                      }}
                    >
                      Action
                    </Typography>
                    <Button
                      aria-label="action box"
                      variant="contained"
                      sx={{
                        backgroundColor: "#E11212",
                        color: "#fff",
                        textTransform: "none",
                        // px: 3,
                        py: 1,
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setOpenCancel(true);
                      }}
                    >
                      Cancel Trip
                    </Button>
                  </Box>
                )}
              {/* )} */}
            </Box>
            <Box
              sx={{
                display: "flex",
                mx: 3,
                mt: 3,
                // mt: 6,
                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "gray",
                    }}
                  >
                    Driver Name
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      driver_full_name
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    my: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "gray",
                    }}
                  >
                    Driver No
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      data?.data?.data?.driver?.phone
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    my: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "gray",
                    }}
                  >
                    Passenger No
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      data?.data?.data?.passenger_phone_number
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  height: "160px",
                  width: "4px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20%",
                  justifyItems: "center",
                  alignItems: "center",
                  // ml: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    textAlign: "center",

                    // p: 1,
                  }}
                >
                  <Typography color={"gray"}>Waiting time</Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      textAlign: "center",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      timeFormatter(data?.data?.data?.waiting_time)
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography color={"gray"} sx={{ textAlign: "center" }}>
                    Total time
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      textAlign: "center",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      data?.data?.data?.start_time &&
                      data?.data?.data?.end_time &&
                      timeFormatter(
                        moment(data?.data?.data?.end_time).diff(
                          moment(data?.data?.data?.start_time),
                          "seconds"
                        )
                      )
                    ) : (
                      "00:00:00"
                    )}
                  </Typography>
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  height: "160px",
                  width: "10px",
                  color: "red",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                  ml: 8,
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography color={"gray"}>Estimated Price</Typography>

                  <Typography sx={{ fontWeight: "bold", fontSize: "2opx" }}>
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      formatedDecimal(data?.data?.data?.bare_cost) + " ETB"
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Total Price
                  </Typography>

                  <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {isLoading ? (
                      <Skeleton animation="wave" width={100} />
                    ) : isSuccess ? (
                      formatedDecimal(data?.data?.data?.cost) + "  ETB"
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "whitesmoke",
                mx: 3,
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  width={"100%"}
                  height={118}
                />
              ) : isSuccess ? (
                <Timeline
                  sx={{
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{
                        m: "auto 40px",
                        textAlign: "left",
                        width: "200px",
                      }}
                    >
                      <Typography> Estimated kilometer</Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "text.primary",
                        }}
                      >
                        {formatedDecimal(data?.data?.data?.bare_distance)} KM
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          backgroundColor: "white",
                          border: "1px solid #08C139",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#08C139",
                            width: "14px",
                            height: "14px",
                            borderRadius: "50%",
                          }}
                        />
                      </TimelineDot>
                      <TimelineConnector
                        sx={{
                          backgroundColor: "#08C139",
                          mt: -1,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        py: "12px",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          ml: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {moment(data?.data?.data?.start_time).format("lll")}
                        </Typography>
                        <Typography
                          sx={{
                            mb: 1,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {data?.data?.data?.start_point_name}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  {data?.data?.data?.status === "ENDED" ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{
                          m: "auto 40px",
                          width: "200px",
                          textAlign: "left",
                        }}
                        align="left"
                      >
                        <Typography> Total kilometer</Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "text.primary",
                          }}
                        >
                          {formatedDecimal(data?.data?.data?.distance)}KM
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{
                            backgroundColor: "#08C139",
                            mb: -1.2,
                          }}
                        />

                        <TimelineDot
                          sx={{
                            backgroundColor: "white",
                            border: "1px solid #FFDC00",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "primary",
                              width: "14px",
                              height: "14px",
                            }}
                          >
                            <Lightbulb fontSize="inherit" color="primary" />
                          </Box>
                        </TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          // mt: 5,
                          px: 2,
                        }}
                      >
                        <Box
                          sx={{
                            ml: 1,
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {moment(data?.data?.data?.end_time).format("lll")}
                          </Typography>
                          <Typography
                            sx={{
                              mb: 1,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data?.data?.data?.end_point_name}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  ) : data?.data?.data?.status === "CANCELLED" ? null : (
                    <Skeleton
                      animation="wave"
                      width={"100%"}
                      height={"50px"}
                      sx={{
                        backgroundColor: "whitesmoke",
                      }}
                    />
                  )}
                </Timeline>
              ) : null}
            </Box>
            <Box
              sx={{
                mt: 1,
                mx: 3,
              }}
            >
              {polyline && (
                <CustomPoliLine
                  polyline={polyline}
                  startingPoint={data?.data?.data?.start_point_name}
                  endPoint={data?.data?.data?.end_point_name}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TripDetails;
