import React from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  Popup,
  LayersControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import polyLineDecoder from "polyline-encoded";
import { bounds } from "leaflet";
import L from "leaflet";
import { BingLayer } from "react-leaflet-bing-v2";
import { BINGMAPKEY } from "../../utils/config";

const endMarkerICon = new L.Icon({
  iconUrl:
    "https://www.clipartmax.com/png/full/236-2368050_free-map-pin-icon-png-map-pin-yellow-png.png",
  iconSize: [35, 35],
  iconAnchor: [15, 20],
  popupAnchor: [0, -25],
});

const startMarker = new L.Icon({
  iconUrl:
    "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-green-icon.png",
  iconSize: [18, 18],
  strokeOpacity: 0.5,
  strokeWeight: 2,
  fillColor: "#000",
  popupAnchor: [0, -25],
});

const CustomPoliLine = ({ polyline, startingPoint, endPoint }) => {
  const { BaseLayer } = LayersControl;

  let decodedPolyline = polyLineDecoder.decode(polyline ? polyline : "???");

  let startingPointPosition = decodedPolyline[0];
  let endPointPosition = decodedPolyline[decodedPolyline.length - 1];

  return (
    <MapContainer
      // bounds={}
      boundsOptions={{
        paddingTopLeft: [0, 0],
        paddingBottomRight: [0, 0],
      }}
      setMinZoom={10}
      // setMaxZoom={14}
      // zoomAnimation={true}
      style={{
        height: "400px",
        width: "  100%",
        backdropFilter: "blur(5px)",
        // marginTop: "30px",
      }}
      zoom={13}
      maxBounds={bounds([[startingPoint], [endPoint]])}
      center={decodedPolyline[Math.floor(decodedPolyline.length / 2)]}
      scrollWheelZoom={true}
      maxZoom={23}
      //   minZoom={15}
      zoomControl={true}
      dragging={true}
      touchZoom={true}
      doubleClickZoom={true}
    >
      <LayersControl>
        <Marker
          position={startingPointPosition}
          icon={startMarker}
          // draggable={true}
        >
          <Popup
            style={{
              borderRadius: "15px",
            }}
            children={
              <div
                style={{
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  Start location
                </span>
                <h3>{startingPoint} </h3>
              </div>
            }
          />

          {/* </Popup> */}
        </Marker>
        <Marker
          // draggable={true}
          position={endPointPosition}
          icon={endMarkerICon}
        >
          <Popup
            children={
              <div
                style={{
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  End location
                </span>
                <h3>{endPoint} </h3>
              </div>
            }
          />
        </Marker>

        <Polyline
          pathOptions={{
            color: "black",
            weight: 4,
            // opacity: 0.5,
            smoothFactor: 4,
          }}
          positions={decodedPolyline}
        />

        {/* <BaseLayer checked name="Bing Maps Roads">
          <BingLayer bingkey={BINGMAPKEY} type="Road" />
        </BaseLayer> */}
        {/* <BaseLayer checked name="Bing Maps satelite">
            <BingLayer bingkey={key} type="AerialWithLabels" />
          </BaseLayer> */}
        <BaseLayer checked name="OpenStreetMap.Mapnik">
          <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        </BaseLayer>
      </LayersControl>
    </MapContainer>
  );
};

export default CustomPoliLine;
