export const access = (roles = [], partial = false) => {
  const auth = JSON.parse(localStorage.getItem("permissions"));
  const permissions = auth?.map((obj) => obj?.name);

  if (!permissions || !Array.isArray(permissions) || !Array.isArray(roles)) {
    return false;
  }

  if (partial) {
    return permissions?.some((val) =>
      roles?.some((each) => val?.includes(each))
    );
  } else {
    return permissions?.some((val) => roles?.includes(val));
  }
};
