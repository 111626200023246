import * as React from "react";
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function QuickSearchToolbar(props) {
  const { user, permissions } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "system/usersExport",
    () => {
      return axios.get(`/system/users`, {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Personal() {
  let navigate = useNavigate();

  const { user, permissions } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountId, setAccountId] = React.useState();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["system/users", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(`/system/users`, {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: [
                        "phone",
                        "first_name",
                        "middle_name",
                        "last_name",
                        "email",
                      ],
                    }),
                  },
                ]
              : []),
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/system/users/${id}/status`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  const {
    mutate: approveReset,
    isLoading: loadingReset,
    error: errorReset,
    data: dataReset,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/system/pin/reset/${id}/approve`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.errorMessage ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (dataReset) {
      enqueueSnackbar("Succesfully approved PIN reset!", {
        variant: "success",
      });
      refetch();
    }
  }, [dataReset]);

  React.useEffect(() => {
    if (errorReset) {
      enqueueSnackbar(
        errorReset?.response?.data?.error?.errorMessage ||
          errorReset?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [errorReset]);

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed company status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  field: "logo",
                  headerName: "Logo",
                  renderCell: (params) => (
                    <Avatar
                      // variant="square"
                      sx={{ width: 48, height: 48 }}
                      src={params?.row?.profile_picture}
                    />
                  ),
                },
                {
                  width: 160,
                  field: "first_name",
                  headerName: "First name",
                },
                {
                  width: 160,
                  field: "middle_name",
                  headerName: "Middle name",
                },
                {
                  width: 160,
                  field: "last_name",
                  headerName: "Last  name",
                },
                {
                  width: 120,
                  field: "phone",
                  headerName: "Phone",
                },
                {
                  width: 160,
                  field: "email",
                  headerName: "Email",
                },
                {
                  field: "gender",
                  headerName: "Gender",
                },
                {
                  field: "personal_balance",
                  width: 100,
                  headerName: "Balance",
                  valueGetter: (params) => {
                    let personal_balance;
                    params?.row?.accounts.filter((items) => {
                      if (items.parent_id === "2003") {
                        personal_balance = items.balance;
                      }
                    });
                    return personal_balance;
                  },
                },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  field: "status",
                  width: 300,
                  type: "actions",
                  getActions: (params) => [
                    <>
                      {params?.row?.pin_status && (
                        <Button
                          onClick={() =>
                            approveReset({
                              id: params?.row?.id,
                            })
                          }
                          size="small"
                          variant="contained"
                          disabled={loadingReset}
                        >
                          Reset PIN
                        </Button>
                      )}
                    </>,
                    <Alert
                      severity={
                        params?.row?.accounts?.[0]?.status === "ACTIVE"
                          ? "success"
                          : params?.row?.accounts?.[0]?.status === "PENDING"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      action={
                        <Switch
                          checked={
                            params?.row?.accounts?.[0]?.status === "ACTIVE"
                              ? true
                              : false
                          }
                          disabled={statusLoading}
                          onChange={() =>
                            changeStatus({
                              id: params?.row?.id,
                              status:
                                params?.row?.accounts?.[0]?.status === "ACTIVE"
                                  ? "INACTIVE"
                                  : "ACTIVE",
                            })
                          }
                          size="small"
                        />
                      }
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.accounts?.[0]?.status}
                      </Typography>
                    </Alert>,
                    <>
                      <IconButton
                        onClick={(event) => {
                          setAccountId(params?.row?.accounts?.[0]?.id);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          onClick={() => navigate(`${accountId}/transactions`)}
                        >
                          Transactions
                        </MenuItem>
                      </Menu>
                    </>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              page={page}
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  // add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
