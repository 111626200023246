import * as React from "react";
import {
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { DatePicker, LocalizationProvider, PickersDay } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { Groups } from "@mui/icons-material";
import {
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryZoomContainer,
} from "victory";
import { VictoryTheme } from "victory";
import { useQuery } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../utils/useAuth";

const InfoCard = ({ title, amount }) => (
  <Paper sx={{ backgroundColor: "#fff", p: 1 }}>
    <Box sx={{ display: "flex", m: 1, alignItems: "center" }}>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            backgroundColor: (theme) => theme.palette.secondary.main,
            color: "#fff",
          }}
        >
          <Groups color="inherit" />
        </Box>
      </Box>
      <Box sx={{ pl: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {amount}
        </Typography>
      </Box>
    </Box>
    <Divider sx={{ mb: 1 }} />
    <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
      <Box
        sx={{
          px: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: (theme) => theme.palette.success.main,
            fontWeight: "bold",
          }}
        >
          +12%
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">This month</Typography>
      </Box>
    </Box>
  </Paper>
);

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const breakdown = [
  {
    status: "Failed",
    total: "20",
  },
  {
    status: "Pending",
    total: "15",
  },
  {
    status: "Completed",
    total: "15",
  },
];

const customers = [
  {
    time: new Date("2021-01-01T00:00:00Z"),
    total: 6,
  },
  {
    time: new Date("2021-02-01T00:00:00Z"),
    total: 3,
  },
  {
    time: new Date("2021-03-01T00:00:00Z"),
    total: 12,
  },
  {
    time: new Date("2021-04-01T00:00:00Z"),
    total: 1,
  },
  {
    time: new Date("2021-05-01T00:00:00Z"),
    total: 9,
  },
  {
    time: new Date("2021-06-01T00:00:00Z"),
    total: 7,
  },
  {
    time: new Date("2021-07-01T00:00:00Z"),
    total: 16,
  },
  {
    time: new Date("2021-08-01T00:00:00Z"),
    total: 0,
  },
  {
    time: new Date("2021-09-01T00:00:00Z"),
    total: 4,
  },
  {
    time: new Date("2021-10-01T00:00:00Z"),
    total: 11,
  },
  {
    time: new Date("2021-11-01T00:00:00Z"),
    total: 8,
  },
  {
    time: new Date("2021-12-01T00:00:00Z"),
    total: 5,
  },
];

const ChartHeader = ({ title, Icon }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        backgroundColor: "black",
        width: 250,
        transform: "translateY(-50%)",
        py: 1,
      }}
    >
      <Box sx={{ display: "flex", color: "#fff", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.5,
            ml: 1,
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "50%",
            // color: "#fff",
          }}
        >
          {Icon}
        </Box>
        <Box sx={{ pl: 1 }} />
        <Typography color="inherit" variant="subtitle2" sx={{}}>
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};

const CustomizedLabel = ({ x, y, fill, width, value }) => (
  <text
    x={x + width + 8}
    y={y + 20}
    fontSize="16"
    fontFamily="sans-serif"
    // fill={fill}
    fill="#FBAB30"
    // dy={15}
    textAnchor="start"
  >
    {value}
  </text>
);

// const CustomizedLabel = ({ payload, x, y, width, height, value }) => {
//   return (
//     <text
//       x={x + width / 2}
//       y={y}
//       fill="#666"
//       textAnchor="middle"
//       dy={-6}
//     >{`value: ${value}`}</text>
//   );
// };

export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  // const [from, setFrom] = React.useState();
  // const [to, setTo] = React.useState();
  const [filter, setFilter] = React.useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const from = watch("from");
  const to = watch("to");
  const type = watch("type");
  const branch_id = watch("branch");
  const product_id = watch("product");

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["reports", filter],
    () => {
      return axios.get("/reports", {
        params: {
          filter: JSON.stringify(filter),
          linkOperator: "and",
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    }
  );

  const [selectedDomain, setSelectedDomain] = React.useState();
  const [zoomDomain, setZoomDomain] = React.useState();

  const {
    isLoading: branchesLoading,
    data: branchesData,
    error: branchesError,
  } = useQuery(
    "branches",
    () => {
      return axios.get("branches", {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      // enabled: false, // turned off by default, manual refetch is needed
    }
  );

  const {
    isLoading: productsLoading,
    data: productsData,
    error: productsError,
  } = useQuery(
    "products",
    () => {
      return axios.get("products", {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      // enabled: false, // turned off by default, manual refetch is needed
    }
  );

  React.useEffect(() => {
    if (from) {
      setFilter((prev) => {
        if (prev?.findIndex((item) => item.columnField === "from") > -1) {
          return [
            ...prev?.map((each) => {
              if (each.columnField === "from") {
                each.value = from;
              }
              return each;
            }),
          ];
        } else {
          return [
            ...prev,
            {
              columnField: "from",
              operatorValue: "is on or after",
              value: from,
            },
          ];
        }
      });
    }
  }, [from]);

  React.useEffect(() => {
    if (to) {
      setFilter((prev) => {
        if (prev?.findIndex((item) => item.columnField === "to") > -1) {
          return [
            ...prev?.map((each) => {
              if (each.columnField === "to") {
                each.value = to;
              }
              return each;
            }),
          ];
        } else {
          return [
            ...prev,
            {
              columnField: "to",
              operatorValue: "is on or before",
              value: to,
            },
          ];
        }
      });
    }
  }, [to]);

  React.useEffect(() => {
    if (type) {
      setFilter((prev) => {
        if (prev?.findIndex((item) => item.columnField === "type") > -1) {
          return [
            ...prev?.map((each) => {
              if (each.columnField === "type") {
                each.value = type;
              }
              return each;
            }),
          ];
        } else {
          return [
            ...prev,
            {
              columnField: "type",
              operatorValue: "=",
              value: type,
            },
          ];
        }
      });
    }
  }, [type]);

  React.useEffect(() => {
    if (branch_id) {
      setFilter((prev) => {
        if (prev?.findIndex((item) => item.columnField === "branch_id") > -1) {
          return [
            ...prev?.map((each) => {
              if (each.columnField === "branch_id") {
                each.value = branch_id;
              }
              return each;
            }),
          ];
        } else {
          return [
            ...prev,
            {
              columnField: "branch_id",
              operatorValue: "=",
              value: branch_id,
            },
          ];
        }
      });
    }
  }, [branch_id]);

  React.useEffect(() => {
    if (product_id) {
      setFilter((prev) => {
        if (prev?.findIndex((item) => item.columnField === "product_id") > -1) {
          return [
            ...prev?.map((each) => {
              if (each.columnField === "product_id") {
                each.value = product_id;
              }
              return each;
            }),
          ];
        } else {
          return [
            ...prev,
            {
              columnField: "product_id",
              operatorValue: "=",
              value: product_id,
            },
          ];
        }
      });
    }
  }, [product_id]);

  return (
    <Container component="main" disableGutters={true} maxWidth="xl">
      <Box sx={{ overflow: "hidden" }}>
        <Grid
          container
          columns={10}
          spacing={{ xs: 1, md: 2 }}
          sx={{ backgroundColor: "#fff", mb: 2, px: 2, py: 1 }}
        >
          <Grid item xs={10} sm={5} md={2}>
            <Controller
              name="type"
              control={control}
              defaultValue={"MONTHLY"}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  select
                  fullWidth
                  label="Type"
                  helperText={errors?.type ? errors?.type?.message : ""}
                  error={errors?.type}
                  {...field}
                >
                  <MenuItem value={"YEARLY"}>Yearly</MenuItem>
                  <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                  <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                  <MenuItem value={"DAILY"}>Daily</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid
            item
            sm={5}
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          ></Grid>
          <Grid item xs={10} sm={5} md={2}>
            <Controller
              name="from"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="From"
                    renderInput={(params) => (
                      <TextField margin="normal" fullWidth {...params} />
                    )}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={10} sm={5} md={2}>
            <Controller
              name="to"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="TO"
                    renderInput={(params) => (
                      <TextField margin="normal" fullWidth {...params} />
                    )}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={10} sm={5} md={2}>
            <Controller
              name="branch"
              control={control}
              defaultValue={"ALL"}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  select
                  fullWidth
                  label="Branch"
                  helperText={errors?.branch ? errors?.branch?.message : ""}
                  error={errors?.branch}
                  {...field}
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {branchesData?.data?.data?.map((item) => (
                    <MenuItem
                      sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                      key={item?.branch_name}
                      value={item?.id}
                    >
                      {item?.branch_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={10} sm={5} md={2}>
            <Controller
              name="product"
              control={control}
              defaultValue={"ALL"}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  select
                  fullWidth
                  label="Product"
                  helperText={errors?.product ? errors?.product?.message : ""}
                  error={errors?.product}
                  {...field}
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {productsData?.data?.data?.map((item) => (
                    <MenuItem
                      sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                      key={item?.name}
                      value={item?.id}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container columns={4} spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={4} sm={2} md={1}>
          <InfoCard
            title="Customers"
            amount={data?.data?.data?.total?.customers?.total}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <InfoCard
            title="Branches"
            amount={data?.data?.data?.total?.branches?.total}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <InfoCard
            title="Refunds"
            amount={data?.data?.data?.total?.refunds?.total}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <InfoCard
            title="Transactions"
            amount={data?.data?.data?.total?.transactions?.total}
          />
        </Grid>
      </Grid>
      <Grid container columns={2} spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={2} md={1}>
          <Paper elevation={2} sx={{ pl: 2, pr: 2, pb: 2, mt: 6 }}>
            <ChartHeader title="Transaction breakdown" Icon={<Groups />} />
            <VictoryChart
              domainPadding={20}
              padding={{ left: 120, right: 80, top: 60, bottom: 60 }}
              // theme={VictoryTheme.material}
              // containerComponent={<VictoryZoomContainer zoomDimension="x" />}
            >
              {/* <VictoryAxis dependentAxis /> */}
              <VictoryAxis />
              <VictoryBar
                horizontal
                // sortOrder="descending"
                labels={({ datum }) => `${Number(datum.total)} ETB`}
                data={data?.data?.data?.transaction_report_total}
                y={(d) => Number(d.total)}
                sortKey="y"
                x="status"
              />
            </VictoryChart>
          </Paper>
        </Grid>

        <Grid item xs={2} md={2}>
          <Paper
            elevation={2}
            sx={{ px: 2, pb: 2, mt: 6, backgroundColor: "#232D30" }}
          >
            <ChartHeader title="Revenue" Icon={<Groups />} />
          </Paper>
        </Grid>

        <Grid item xs={2} md={1}>
          <Paper
            elevation={2}
            sx={{ px: 2, pb: 2, mt: 6, backgroundColor: "#fff" }}
          >
            <ChartHeader title="Revenue" Icon={<Groups />} />
            <VictoryChart
              domainPadding={20}
              horizontal
              // theme={VictoryTheme.material}
              containerComponent={<VictoryZoomContainer zoomDimension="x" />}
            >
              <VictoryAxis dependentAxis />
              <VictoryBar
                data={customers}
                labels={({ datum }) => `${moment(datum.time).format("ll")}`}
                y="total"
                x="time"
              />
            </VictoryChart>
            <VictoryChart
              // width={550}
              // height={300}
              scale={{ x: "time" }}
              containerComponent={
                <VictoryZoomContainer
                  zoomDimension="x"
                  zoomDomain={zoomDomain}
                  onZoomDomainChange={(domain) => setSelectedDomain(domain)}
                />
              }
            >
              <VictoryLine
                data={[
                  { x: new Date(1982, 1, 1), y: 125 },
                  { x: new Date(1987, 1, 1), y: 257 },
                  { x: new Date(1993, 1, 1), y: 345 },
                  { x: new Date(1997, 1, 1), y: 515 },
                  { x: new Date(2001, 1, 1), y: 132 },
                  { x: new Date(2005, 1, 1), y: 305 },
                  { x: new Date(2011, 1, 1), y: 270 },
                  { x: new Date(2015, 1, 1), y: 470 },
                ]}
              />
            </VictoryChart>

            <VictoryChart
              scale={{ x: "time" }}
              height={60}
              padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
              containerComponent={
                <VictoryBrushContainer
                  brushDimension="x"
                  brushDomain={selectedDomain}
                  onBrushDomainChange={(domain) => setZoomDomain(domain)}
                />
              }
            >
              <VictoryAxis />
              <VictoryLine
                data={[
                  { x: new Date(1982, 1, 1), y: 125 },
                  { x: new Date(1987, 1, 1), y: 257 },
                  { x: new Date(1993, 1, 1), y: 345 },
                  { x: new Date(1997, 1, 1), y: 515 },
                  { x: new Date(2001, 1, 1), y: 132 },
                  { x: new Date(2005, 1, 1), y: 305 },
                  { x: new Date(2011, 1, 1), y: 270 },
                  { x: new Date(2015, 1, 1), y: 470 },
                ]}
              />
            </VictoryChart>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1}>
          <Paper
            elevation={2}
            sx={{ px: 2, pb: 2, mt: 6, backgroundColor: "#fff" }}
          >
            <ChartHeader title="Revenue" Icon={<Groups />} />
            <VictoryChart
              domainPadding={20}
              theme={VictoryTheme.material}
              containerComponent={<VictoryZoomContainer zoomDimension="x" />}
            >
              <VictoryBar data={customers} y="total" x="time" />
            </VictoryChart>
            <VictoryChart
              // width={550}
              // height={300}
              scale={{ x: "time" }}
              containerComponent={
                <VictoryZoomContainer
                  zoomDimension="x"
                  zoomDomain={zoomDomain}
                  onZoomDomainChange={(domain) => setSelectedDomain(domain)}
                />
              }
            >
              <VictoryLine
                data={[
                  { x: new Date(1982, 1, 1), y: 125 },
                  { x: new Date(1987, 1, 1), y: 257 },
                  { x: new Date(1993, 1, 1), y: 345 },
                  { x: new Date(1997, 1, 1), y: 515 },
                  { x: new Date(2001, 1, 1), y: 132 },
                  { x: new Date(2005, 1, 1), y: 305 },
                  { x: new Date(2011, 1, 1), y: 270 },
                  { x: new Date(2015, 1, 1), y: 470 },
                ]}
              />
            </VictoryChart>

            <VictoryChart
              scale={{ x: "time" }}
              height={60}
              padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
              containerComponent={
                <VictoryBrushContainer
                  brushDimension="x"
                  brushDomain={selectedDomain}
                  onBrushDomainChange={(domain) => setZoomDomain(domain)}
                />
              }
            >
              <VictoryAxis />
              <VictoryLine
                data={[
                  { x: new Date(1982, 1, 1), y: 125 },
                  { x: new Date(1987, 1, 1), y: 257 },
                  { x: new Date(1993, 1, 1), y: 345 },
                  { x: new Date(1997, 1, 1), y: 515 },
                  { x: new Date(2001, 1, 1), y: 132 },
                  { x: new Date(2005, 1, 1), y: 305 },
                  { x: new Date(2011, 1, 1), y: 270 },
                  { x: new Date(2015, 1, 1), y: 470 },
                ]}
              />
            </VictoryChart>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
