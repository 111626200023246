import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  ExpandMore,
  FileDownload,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { useParams } from "react-router-dom";

const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function QuickSearchToolbar(props) {
  const params = useParams();
  const { user, permissions } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "programsExport",
    () => {
      return axios.get(`/system/companies/${params?.companyId}/programs`, {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Program() {
  const params = useParams();
  const { user, permissions } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["programs", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(`/system/companies/${params?.companyId}/programs`, {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: ["name", "description"],
                    }),
                  },
                ]
              : []),
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/system/companies/${params?.companyId}/programs/${id}`,
      // `/users/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed user status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 160,
                  field: "name",
                  headerName: "Name",
                },
                {
                  width: 220,
                  field: "description",
                  headerName: "Description",
                },
                {
                  width: 160,
                  field: "spending_limit_amount",
                  headerName: "Spending Limit",
                  valueGetter: (params) =>
                    params?.row?.spending_limit_amount > 0 &&
                    params?.row?.spending_limit_amount
                      ? `${params?.row?.spending_limit_amount} ETB ${params?.row?.spending_limit_duration}`
                      : "Unlimited",
                },
                {
                  width: 120,
                  field: "trip_limit_amount",
                  headerName: "Trip Limit",
                  valueGetter: (params) =>
                    params?.row?.trip_limit_amount > 0 &&
                    params?.row?.trip_limit_amount
                      ? `${params?.row?.trip_limit_amount} trips ${params?.row?.trip_limit_duration}`
                      : "Unlimited",
                },
                {
                  width: 250,
                  field: "weekdays",
                  headerName: "Time Limit",
                  valueGetter: (params) =>
                    params?.row?.weekdays?.length > 0 ||
                    params?.row?.from ||
                    params?.row?.to
                      ? `${
                          params?.row?.weekdays?.length > 0
                            ? params?.row?.weekdays?.map(
                                (index) => daysOfTheWeek[index - 1]?.[0]
                              )
                            : "Unlimited"
                        } / ${
                          params?.row?.from
                            ? moment(params?.row?.from).format("LT")
                            : ""
                        } - ${
                          params?.row?.to
                            ? moment(params?.row?.to).format("LT")
                            : ""
                        }`
                      : "Unlimited",
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                // {
                //   field: "status",
                //   width: 240,
                //   type: "actions",
                //   getActions: (params) => [
                //     <Alert
                //       severity={
                //         params?.row?.status === "ACTIVE"
                //           ? "success"
                //           : params?.row?.status === "PENDING"
                //           ? "info"
                //           : "error"
                //       }
                //       sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                //       action={
                //         <Switch
                //           checked={
                //             params?.row?.status === "ACTIVE" ? true : false
                //           }
                //           disabled={statusLoading}
                //           onChange={() =>
                //             changeStatus({
                //               id: params?.row?.id,
                //               status:
                //                 params?.row?.status === "ACTIVE"
                //                   ? "INACTIVE"
                //                   : "ACTIVE",
                //             })
                //           }
                //           size="small"
                //         />
                //       }
                //     >
                //       <Typography
                //         sx={{ textTransform: "capitalize" }}
                //         variant="body2"
                //       >
                //         {params?.row?.status}
                //       </Typography>
                //     </Alert>,
                //   ],
                // },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              page={page}
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
