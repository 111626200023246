export const timeFormatter = (seconds = 0) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : "00";
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : "00";
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : "00";
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

// display only 2 decimal places without rounding
export const roundToTwoDecimalPlaces = (number = 0) => {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number;
  }
  const num = numString.slice(0, decimalIndex + 3);
  return Number(num);
};
