import * as React from "react";
import { useLocalStorage } from "./useLocalStorage";
import { QueryCache, useMutation, useQuery } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Slide } from "@mui/material";
import { SSO_BASE_URL } from "./config";
import { queryClient } from "../App";

export function useAuth() {
  //   const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("auth");
  const [permissions, setPermissions] = useLocalStorage("permissions");
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation((auth) => {
    return axios({
      method: "post",
      url: "/api/login",
      baseURL: SSO_BASE_URL,
      data: auth,
    });
    // return axios.post("/auth/login", auth);
  });

  const {
    isLoading: getPermissionsLoading,
    data,
    error,
    refetch,
    status,
  } = useQuery(
    ["access_permissions", { status: "active", user }],
    (status, user) => {
      console.log({ user, status: status?.queryKey?.[1]?.user });
      return axios.get(`system/access_permissions`, {
        headers: {
          Authorization: `Bearer ${status?.queryKey?.[1]?.user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  React.useEffect(() => {
    if (data) {
      console.log({ data: data?.data?.data });
      setPermissions(data?.data?.data?.system_permissions);
    }
  }, [data, setPermissions]);

  let signin = async (params, callback) => {
    return mutate(params, {
      onSuccess: (data) => {
        console.log({ data: data?.data?.access_token });
        setUser(data?.data?.data);

        refetch();

        // console.log({ callback });

        if (callback) {
          console.log({ callback });
          callback();
        }
      },
      onError: (error) => {
        console.error({
          error,
          msg: error?.response?.data?.error?.errorMessage,
          default: error?.message,
        });
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Slide,
          }
        );
      },
    });
  };

  let signout = (callback) => {
    setUser(null);
    setPermissions(null);
    // const queryCache = new QueryCache({
    //   onError: (error) => {
    //     console.log(error);
    //   },
    //   onSuccess: (data) => {
    //     console.log(data);
    //   },
    // });
    // queryCache.clear();
    queryClient.clear();
    if (callback) {
      callback();
    }
  };

  return { user, isLoading, permissions, signin, signout };
}
