import * as React from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

function QuickSearchToolbar(props) {
  const { user } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "productsExport",
    () => {
      return axios.get("/products", {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Product() {
  const { user } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["products", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get("/products", {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: [
                        "msisdn",
                        "status",
                        "payment_type",
                        "transaction_name",
                        "method",
                      ],
                    }),
                  },
                ]
              : []),
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    }
  );

  const {
    isLoading: branchesLoading,
    data: branchesData,
    error: branchesError,
  } = useQuery(
    "branches",
    () => {
      return axios.get("branches", {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      // enabled: false, // turned off by default, manual refetch is needed
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/products/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed product status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 160,
                  field: "name",
                  headerName: "Name",
                },
                {
                  width: 160,
                  field: "default_customer_type",
                  headerName: "Default customer type",
                },
                {
                  width: 160,
                  field: "default_transaction",
                  headerName: "Default transaction",
                },
                {
                  width: 160,
                  field: "utility_name",
                  headerName: "Utility name",
                },
                {
                  width: 160,
                  field: "call_back_url",
                  headerName: "Call back url",
                },
                {
                  width: 160,
                  field: "is_default",
                  headerName: "Is default",
                  type: "boolean",
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 240,
                  type: "actions",
                  getActions: (params) => [
                    <Alert
                      severity={
                        params?.row?.status === "active"
                          ? "success"
                          : params?.row?.status === "pending"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      {...(params?.row?.status !== "blovcked" && {
                        action: (
                          <Switch
                            checked={
                              params?.row?.status === "active" ? true : false
                            }
                            disabled={statusLoading}
                            onChange={() =>
                              changeStatus({
                                id: params?.row?.id,
                                status:
                                  params?.row?.status === "active"
                                    ? "blocked"
                                    : "active",
                              })
                            }
                            size="small"
                          />
                        ),
                      })}
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.status}
                      </Typography>
                    </Alert>,
                    <Button
                      startIcon={<Edit />}
                      onClick={() => {
                        // console.log("params", params?.row);
                        setOpenEdit(params?.row);
                      }}
                    >
                      Edit
                    </Button>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              page={page}
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={data?.data?.meta_data?.total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {openAdd && (
        <AddProductForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
          branchesData={branchesData}
          branchesLoading={branchesLoading}
        />
      )}

      {openEdit && (
        <EditProductForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
          branchesData={branchesData}
          branchesLoading={branchesLoading}
        />
      )}
    </Container>
  );
}

const AddProductForm = ({
  loading,
  refetch,
  openAdd,
  setOpenAdd,
  branchesData,
  branchesLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/products`,
      data,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added product!`, {
        variant: "success",
      });
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Drawer
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      anchor="right"
      open={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      <Box
        sx={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          mt: 12,
          mb: 12,
        }}
      >
        {/* <Toolbar /> */}
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            py: 3,
            px: 4,
            width: 400,
            position: "fixed",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 3,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Add New Product
            </Typography>
            <Typography variant="body2" gutterBottom></Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box>
            <IconButton
              onClick={() => setOpenAdd(false)}
              sx={{ color: "#000" }}
            >
              <Close color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ m: 4 }}>
          <Box sx={{ mt: 1 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Name"
                  helperText={errors?.name ? errors?.name?.message : ""}
                  error={errors?.name}
                  {...field}
                />
              )}
            />

            <Controller
              name="default_customer_type"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Default customer type"
                  helperText={
                    errors?.default_customer_type
                      ? errors?.default_customer_type?.message
                      : ""
                  }
                  error={errors?.default_customer_type}
                  {...field}
                />
              )}
            />

            <Controller
              name="call_back_url"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Call back url"
                  helperText={
                    errors?.call_back_url ? errors?.call_back_url?.message : ""
                  }
                  error={errors?.call_back_url}
                  {...field}
                />
              )}
            />

            <Controller
              name="default_transaction"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Default transaction"
                  helperText={
                    errors?.default_transaction
                      ? errors?.default_transaction?.message
                      : ""
                  }
                  error={errors?.default_transaction}
                  {...field}
                />
              )}
            />

            <Controller
              name="utility_name"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Utility name"
                  helperText={
                    errors?.utility_name ? errors?.utility_name?.message : ""
                  }
                  error={errors?.utility_name}
                  {...field}
                />
              )}
            />

            {!branchesLoading && branchesData && (
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <Autocomplete
                    multiple
                    options={branchesData?.data?.data}
                    getOptionLabel={(option) => option?.branch_name}
                    filterSelectedOptions
                    onChange={(e, data) => onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="branches"
                        margin="normal"
                        fullWidth
                        size="small"
                        helperText={
                          errors?.branches ? errors?.branches?.message : ""
                        }
                        error={errors?.branches}
                      />
                    )}
                    {...rest}
                  />
                )}
                // onChange={([, data]) => data}
                name="branches"
                control={control}
              />
            )}

            <Controller
              name="is_default"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      inputRef={ref}
                    />
                  }
                  label="Is Default"
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            py: 3,
            px: 4,
            width: 400,
            position: "fixed",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            zIndex: (theme) => theme.zIndex.drawer + 3,
          }}
        >
          <Button
            color="primary"
            onClick={handleSubmit((data) => {
              const branches = data?.branches?.map((branch) => ({
                id: branch?.id,
              }));
              mutate({ ...data, branches });
            })}
            variant="contained"
            disabled={loading}
            sx={{ px: 4 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

const EditProductForm = ({
  loading,
  refetch,
  openEdit,
  setOpenEdit,
  branchesData,
  branchesLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/products/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
  });

  // React.useEffect(() => {
  //   if (openEdit) {
  //     setValue(
  //       "branches",
  //       branchesData?.data?.data?.filter((branch) => {
  //         for (let i = 0; i < openEdit?.branches?.length; i++) {
  //           if (branch?.id === openEdit?.branches?.[i]?.id) {
  //             return openEdit?.branches?.[i];
  //           }
  //         }
  //         openEdit?.branches?.find((item) => item?.id === branch?.id);
  //       })
  //     );
  //   }
  // }, [openEdit]);

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully edited product!`, {
        variant: "success",
      });
      refetch();
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Drawer
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      anchor="right"
      open={!!openEdit}
      onClose={() => {
        setOpenEdit(false);
      }}
    >
      <Box
        sx={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          mt: 12,
          mb: 12,
        }}
      >
        {/* <Toolbar /> */}
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            py: 3,
            px: 4,
            width: 400,
            position: "fixed",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 3,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Edit Product
            </Typography>
            <Typography variant="body2" gutterBottom></Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box>
            <IconButton
              onClick={() => {
                setOpenEdit(false);
              }}
              sx={{ color: "#000" }}
            >
              <Close color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ m: 4 }}>
          <Box sx={{ mt: 1 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
              }}
              defaultValue={openEdit?.name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Name"
                  helperText={errors?.name ? errors?.name?.message : ""}
                  error={errors?.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="default_customer_type"
              control={control}
              defaultValue={openEdit?.default_customer_type}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Default customer type"
                  helperText={
                    errors?.default_customer_type
                      ? errors?.default_customer_type?.message
                      : ""
                  }
                  error={errors?.default_customer_type}
                  {...field}
                />
              )}
            />
            <Controller
              name="call_back_url"
              control={control}
              defaultValue={openEdit?.call_back_url}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Call back url"
                  helperText={
                    errors?.call_back_url ? errors?.call_back_url?.message : ""
                  }
                  error={errors?.call_back_url}
                  {...field}
                />
              )}
            />
            <Controller
              name="default_transaction"
              control={control}
              defaultValue={openEdit?.default_transaction}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Default transaction"
                  helperText={
                    errors?.default_transaction
                      ? errors?.default_transaction?.message
                      : ""
                  }
                  error={errors?.default_transaction}
                  {...field}
                />
              )}
            />
            <Controller
              name="utility_name"
              control={control}
              defaultValue={openEdit?.utility_name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Utility name"
                  helperText={
                    errors?.utility_name ? errors?.utility_name?.message : ""
                  }
                  error={errors?.utility_name}
                  {...field}
                />
              )}
            />
            {!branchesLoading && branchesData && (
              <Controller
                name="branches"
                control={control}
                defaultValue={branchesData?.data?.data?.filter((branch) => {
                  for (let i = 0; i < openEdit?.branches?.length; i++) {
                    if (branch?.id === openEdit?.branches?.[i]?.id) {
                      return openEdit?.branches?.[i];
                    }
                  }
                  openEdit?.branches?.find((item) => item?.id === branch?.id);
                })}
                render={({ field: { onChange, ...rest } }) => (
                  <Autocomplete
                    multiple
                    // value={value}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    id="tags-outlined"
                    options={branchesData?.data?.data}
                    getOptionLabel={(option) => option?.branch_name}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Branches"
                        placeholder="Favorites"
                      />
                    )}
                    {...rest}
                  />
                )}
              />
            )}

            <Controller
              name="is_default"
              control={control}
              defaultValue={openEdit?.is_default}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      inputRef={ref}
                    />
                  }
                  label="Is Default"
                />
              )}
            />
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            p: 4,
            width: 400,
            position: "fixed",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            zIndex: (theme) => theme.zIndex.drawer + 3,
          }}
        >
          <Button
            color="primary"
            onClick={handleSubmit((data) => {
              const branches = data?.branches?.map((branch) => ({
                id: branch?.id,
              }));
              mutate({ ...data, branches });
            })}
            variant="contained"
            disabled={loading}
            sx={{
              px: 4,
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
