import { CssBaseline, Slide, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main";
import SignIn from "./pages/signIn";
import { theme } from "./utils/theme";
import axios from "axios";
import { BASE_URL, VERSION } from "./utils/config";
import { RequireAuth } from "./components/requireAuth";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import Transaction from "./pages/transaction";
import Customer from "./pages/customer";
import Branch from "./pages/branch";
import User from "./pages/user";
import Role from "./pages/role";
import Dashboard from "./pages/dashboard";
import BankConfiguration from "./pages/bank_configuration";
import Product from "./pages/product";
import Program from "./pages/program";
import Group from "./pages/group";
import GroupDetail from "./pages/group_detail";
import { access } from "./utils/access";
import Company from "./pages/company";
import CompanyUser from "./pages/company_user";
import CompanyTransaction from "./pages/company_transaction";
import CompanyGroupTransaction from "./pages/company_group_transaction";
import Personal from "./pages/personal";
import Driver from "./pages/driver";
import PersonalTransaction from "./pages/personal_transaction";
import COA from "./pages/coa";
import DriverTransaction from "./pages/driver_transaction";
import CompanyPrepaidBill from "./pages/company_prepaid_bill";
import CompanyPostpaidBill from "./pages/company_postpaid_bill";
import ChangePassword from "./pages/change_password";
import CreditTransaction from "./pages/credit_transaction";
import StreetPickup from "./pages/street_pickup/street_pickup";
import { useAuth } from "./utils/useAuth";
import TripDetails from "./pages/street_pickup/trip_details";

// require("dotenv").config();
axios.defaults.baseURL = `${BASE_URL}/${VERSION}`;
// axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
// axios.defaults.headers.common["Authorization"] = `Bearer ${user?.access_token}`;

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
      >
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="login" element={<SignIn />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Main />
                  </RequireAuth>
                }
              >
                <Route index element={<User />} />
                {/* <Route index element={<Dashboard />} /> */}
                {/* <Route path="users" element={<User />} /> */}
                <Route path="roles" element={<Role />} />
                {/* {access(["get companies", "create company"]) && ( */}
                <Route path="companies" element={<Company />} />
                {/* )} */}
                <Route path="companies/:companyId/groups" element={<Group />} />
                <Route
                  path="companies/:companyId/groups/:groupId"
                  element={<GroupDetail />}
                />
                <Route
                  path="companies/:companyId/users"
                  element={<CompanyUser />}
                />
                <Route
                  path="companies/:companyId/transactions"
                  element={<CompanyTransaction />}
                />
                <Route
                  path="companies/:companyId/prepaid"
                  element={<CompanyPrepaidBill />}
                />
                <Route
                  path="companies/:companyId/postpaid"
                  element={<CompanyPostpaidBill />}
                />
                <Route
                  path="companies/:companyId/groups/:groupId/transactions"
                  element={<CompanyGroupTransaction />}
                />
                <Route path="transactions" element={<Transaction />} />
                <Route path="personals" element={<Personal />} />
                <Route
                  path="personals/:accountId/transactions"
                  element={<PersonalTransaction />}
                />
                <Route path="drivers" element={<Driver />} />
                <Route
                  path="drivers/:accountId/transactions"
                  element={<DriverTransaction />}
                />
                <Route path="coa" element={<COA />} />
                <Route path="coa/:accountId" element={<COA />} />
                <Route path="coa/:accountId/:accountId" element={<COA />} />
                <Route
                  path="coa/:accountId/:accountId/:accountId"
                  element={<COA />}
                />
                <Route
                  path="coa/:accountId/:accountId/:accountId/:accountId"
                  element={<COA />}
                />

                <Route
                  path="companies/:companyId/programs"
                  element={<Program />}
                />
                <Route
                  path="credit-transaction"
                  element={<CreditTransaction />}
                />
                {/* {access(["get street pickup trips"]) && ( */}
                <Route path="street-pickup" element={<StreetPickup />} />
                {/* )} */}

                {/* {access(["get street pickup trips"]) && ( */}
                <Route
                  path="street-pickup/:tripID/tripDetails"
                  element={<TripDetails />}
                />
                {/* )} */}
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
