import { Navigate, useLocation } from "react-router";
import { useAuth } from "../utils/useAuth";

export function RequireAuth({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to="/login"
        // state={{ from: location }}
      />
    );
  }

  if (user?.password_status === "GENERATED") {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/change-password" state={{ from: location }} />;
  }

  return children;
}
