import * as React from "react";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Fab,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  AddCircle,
  Clear,
  Close,
  Delete,
  Edit,
  FileDownload,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Outlet, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import choosePhoto from "../assets/choosePhoto.svg";

function Dropzone({ files, setFiles }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      // files.forEach((file) => URL.revokeObjectURL(file.preview));
      console.log({ files });
    },
    [files]
  );

  return (
    <Box sx={{ position: "relative", width: 160 }}>
      <Box sx={{ margin: 2, width: 144, height: 144 }} {...getRootProps()}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <IconButton onClick={() => {}}>
              <Add sx={{ backgroundColor: "#fff", borderRadius: "50%" }} />
            </IconButton>
          }
        >
          <Avatar
            sx={{ width: 144, height: 144, borderWidth: 40, border: "black" }}
            alt="Travis Howard"
            src={files?.[0]?.preview || choosePhoto}
          />
          <input {...getInputProps()} />
        </Badge>
      </Box>

      {files?.[0] && (
        <Box sx={{ position: "absolute", top: 60, left: 60, elevation: 4 }}>
          <Fab
            sx={{}}
            onClick={() => {
              setFiles();
            }}
          >
            <Delete sx={{ color: "red" }} fontSize="large" />
          </Fab>
        </Box>
      )}

      {/* {isDragActive ? (
        <Typography>Drop the files here ...</Typography>
      ) : (
        <Typography>
          Drag 'n' drop some files here, or click to select files
        </Typography>
      )} */}
    </Box>
  );
}

function QuickSearchToolbar(props) {
  const { user, permissions } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "companiesExport",
    () => {
      return axios.get(`/system/companies`, {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Company() {
  let navigate = useNavigate();

  const { user, permissions } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyId, setCompanyId] = React.useState();
  const [accountType, setAccountType] = React.useState();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["companies", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(`/system/companies`, {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: ["name", "phone", "email", "account_type"],
                    }),
                  },
                ]
              : []),
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/system/companies/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed company status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  field: "logo",
                  headerName: "Logo",
                  renderCell: (params) => (
                    <Avatar
                      variant="square"
                      sx={{ width: "auto", height: 40 }}
                      src={params?.row?.logo}
                    />
                  ),
                },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 220,
                  field: "name",
                  headerName: "Name",
                },
                {
                  width: 120,
                  field: "phone",
                  headerName: "Phone",
                },
                {
                  width: 160,
                  field: "email",
                  headerName: "Email",
                },
                {
                  width: 160,
                  field: "account_type",
                  headerName: "Account type",
                },
                {
                  // width: 220,
                  field: "accounts",
                  headerName: "Balance",
                  type: "number",
                  valueGetter: (params) => params?.row?.accounts?.[0]?.balance,
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 280,
                  type: "actions",
                  getActions: (params) => [
                    <Alert
                      severity={
                        params?.row?.status === "ACTIVE"
                          ? "success"
                          : params?.row?.status === "PENDING"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      action={
                        <Switch
                          checked={
                            params?.row?.status === "ACTIVE" ? true : false
                          }
                          disabled={statusLoading}
                          onChange={() =>
                            changeStatus({
                              id: params?.row?.id,
                              status:
                                params?.row?.status === "ACTIVE"
                                  ? "INACTIVE"
                                  : "ACTIVE",
                            })
                          }
                          size="small"
                        />
                      }
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.status}
                      </Typography>
                    </Alert>,
                    <Button
                      startIcon={<Edit />}
                      onClick={() => {
                        // console.log("params", params?.row);
                        setOpenEdit(params?.row);
                      }}
                    >
                      Edit
                    </Button>,
                    <>
                      <IconButton
                        onClick={(event) => {
                          setCompanyId(params?.row?.id);
                          setAccountType(params?.row?.account_type);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          onClick={() => navigate(`${companyId}/groups`)}
                        >
                          Corporate groups
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`${companyId}/programs`)}
                        >
                          Corporate programs
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`${companyId}/transactions`)}
                        >
                          Corporate transactions
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (accountType === "PREPAID") {
                              navigate(`${companyId}/prepaid`);
                            } else {
                              navigate(`${companyId}/postpaid`);
                            }
                          }}
                        >
                          Corporate bills
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`${companyId}/users`)}
                        >
                          Corporate system users
                        </MenuItem>
                      </Menu>
                    </>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              page={page}
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* <Outlet /> */}

      {openAdd && (
        <AddCompanyForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
        />
      )}

      {openEdit && (
        <EditCompanyForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const AddCompanyForm = ({ loading, refetch, openAdd, setOpenAdd }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [files, setFiles] = React.useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/system/companies`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  const { mutate: fileMutate, isLoading: fileIsLoading } = useMutation(
    (data) => {
      return axios({
        method: "POST",
        url: `/system/store`,
        data,
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added company!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add New Company
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropzone files={files} setFiles={setFiles} errors={errors} />
              </Box>
              {/* <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <Input
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Email"
                    helperText={errors?.email ? errors?.email?.message : ""}
                    error={errors?.email}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    type="number"
                    label="Phone number"
                    helperText={errors?.phone ? errors?.phone?.message : ""}
                    error={errors?.phone}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="license_number"
                control={control}
                rules={{
                  required: "License number is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="License number"
                    helperText={
                      errors?.license_number
                        ? errors?.license_number?.message
                        : ""
                    }
                    error={errors?.license_number}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="house_num"
                control={control}
                rules={{
                  required: "House number is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="House number"
                    helperText={
                      errors?.house_num ? errors?.house_num?.message : ""
                    }
                    error={errors?.house_num}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="sub_city"
                control={control}
                rules={{
                  required: "Subcity is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Subcity"
                    helperText={
                      errors?.sub_city ? errors?.sub_city?.message : ""
                    }
                    error={errors?.sub_city}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: "Address is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Address"
                    helperText={errors?.address ? errors?.address?.message : ""}
                    error={errors?.address}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="account_type"
                control={control}
                rules={{
                  required: "Account type is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    select
                    size="small"
                    label="Account type"
                    helperText={
                      errors?.account_type ? errors?.account_type?.message : ""
                    }
                    error={errors?.account_type}
                    {...field}
                  >
                    <MenuItem value="PREPAID">Pre Paid</MenuItem>
                    <MenuItem value="POSTPAID">Post Paid</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((form) => {
            if (files?.length !== 1) {
              enqueueSnackbar("Company logo is required!", {
                variant: "error",
              });
              return;
            }
            const formData = new FormData();
            formData.append("file", files[0]);

            fileMutate(formData, {
              onSuccess: ({ data }) => {
                console.log({ data: data?.data });
                return mutate({ ...form, logo: data?.data?.file_url });
              },
              onError: (error) => {
                enqueueSnackbar(
                  error?.response?.data?.error?.errorMessage ||
                    error?.message ||
                    "Network Error!",
                  {
                    variant: "error",
                  }
                );
                return;
              },
            });
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditCompanyForm = ({ loading, refetch, openEdit, setOpenEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions } = useAuth();
  const [files, setFiles] = React.useState([{ preview: openEdit?.logo }]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/system/companies/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  const { mutate: fileMutate, isLoading: fileIsLoading } = useMutation(
    (data) => {
      return axios({
        method: "POST",
        url: `/system/store`,
        data,
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully updated company!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openEdit}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenEdit(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Company
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropzone files={files} setFiles={setFiles} errors={errors} />
              </Box>
              {/* <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <Input
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                defaultValue={openEdit?.name}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                }}
                defaultValue={openEdit?.email}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Email"
                    helperText={errors?.email ? errors?.email?.message : ""}
                    error={errors?.email}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required",
                }}
                defaultValue={openEdit?.phone}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    type="number"
                    label="Phone number"
                    helperText={errors?.phone ? errors?.phone?.message : ""}
                    error={errors?.phone}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="license_number"
                control={control}
                rules={{
                  required: "License number is required",
                }}
                defaultValue={openEdit?.license_number}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="License number"
                    helperText={
                      errors?.license_number
                        ? errors?.license_number?.message
                        : ""
                    }
                    error={errors?.license_number}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="house_num"
                control={control}
                rules={{
                  required: "House number is required",
                }}
                defaultValue={openEdit?.house_num}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="House number"
                    helperText={
                      errors?.house_num ? errors?.house_num?.message : ""
                    }
                    error={errors?.house_num}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="sub_city"
                control={control}
                rules={{
                  required: "Subcity is required",
                }}
                defaultValue={openEdit?.sub_city}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Subcity"
                    helperText={
                      errors?.sub_city ? errors?.sub_city?.message : ""
                    }
                    error={errors?.sub_city}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: "Address is required",
                }}
                defaultValue={openEdit?.address}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Address"
                    helperText={errors?.address ? errors?.address?.message : ""}
                    error={errors?.address}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="account_type"
                control={control}
                rules={{
                  required: "Account type is required",
                }}
                defaultValue={openEdit?.account_type}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    select
                    size="small"
                    label="Account type"
                    helperText={
                      errors?.account_type ? errors?.account_type?.message : ""
                    }
                    error={errors?.account_type}
                    {...field}
                  >
                    <MenuItem value="PREPAID">Pre Paid</MenuItem>
                    <MenuItem value="POSTPAID">Post Paid</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenEdit(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((form) => {
            if (files?.length !== 1) {
              enqueueSnackbar("Company logo is required!", {
                variant: "error",
              });
              return;
            }
            if (files?.[0]?.path) {
              const formData = new FormData();
              formData.append("file", files[0]);

              fileMutate(formData, {
                onSuccess: ({ data }) => {
                  console.log({ data: data?.data });
                  return mutate({ ...form, logo: data?.data?.file_url });
                },
                onError: (error) => {
                  enqueueSnackbar(
                    error?.response?.data?.error?.errorMessage ||
                      error?.message ||
                      "Network Error!",
                    {
                      variant: "error",
                    }
                  );
                  return;
                },
              });
            } else {
              return mutate(form);
            }
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
