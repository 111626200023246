import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { SSO_BASE_URL } from "../utils/config";
import { NavLink, useLocation } from "react-router-dom";

export default function ChangePassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const { user, loading, permissions, signout } = useAuth();

  const { mutate, isLoading, data, error } = useMutation((form) => {
    return axios({
      method: "PATCH",
      // url: `/roles`,
      url: `/oauth/password`,
      baseURL: SSO_BASE_URL,
      data: form,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  const onSubmit = (form) => mutate(form);

  React.useEffect(() => {
    console.log({ location });
  }, []);

  React.useEffect(() => {
    if (data) {
      console.log({ data });
      if (location?.state === "LOGOUT") {
        navigate("/");
      } else {
        signout(() => {
          navigate("/login");
        });
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          // paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "red",
          height: "100vh",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Controller
            name="old_password"
            control={control}
            rules={{
              required: "Old password is required",
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                type="password"
                label="Old password"
                helperText={
                  errors?.old_password ? errors?.old_password?.message : ""
                }
                error={errors?.old_password}
                {...field}
              />
            )}
          />
          <Controller
            name="new_password"
            control={control}
            rules={{
              required: "New password is required",
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                type="password"
                label="New password"
                helperText={
                  errors?.new_password ? errors?.new_password?.message : ""
                }
                error={errors?.new_password}
                {...field}
              />
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            rules={{
              required: "Confirm password is required",
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                type="password"
                label="Confirm password"
                helperText={
                  errors?.confirm_password
                    ? errors?.confirm_password?.message
                    : ""
                }
                error={errors?.confirm_password}
                {...field}
              />
            )}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            // disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            Change Password
          </Button>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                size="small"
                onClick={() => {
                  if (location?.state === "LOGOUT") {
                    navigate(-1);
                  } else {
                    signout(() => {
                      navigate("/login");
                    });
                  }
                }}
                // startIcon={<Logout fontSize="small" />}
              >
                {location?.state === "LOGOUT" ? `Go Back` : `Go back to login`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
