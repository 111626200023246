import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { MutationCache, useMutation, useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../utils/useAuth";
import moment from "moment";
import {
  AccessTime,
  CalendarViewMonthSharp,
  Clear,
  Close,
  DateRangeOutlined,
  RemoveRedEye,
  Search,
  Today,
} from "@mui/icons-material";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router";
import { access } from "../../utils/access";
import { roundToTwoDecimalPlaces } from "../../utils/dataFornat";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const TripsCount = ({
  status,
  amount,
  color,
  textColor = "text.secondary",
  fontWeight,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "70%",
        alignItems: "center",
        my: 0.81,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: `${color}`,
            p: 1,
            height: "10px",
            width: "10px",
            borderRadius: "50%",
            mx: 4,
          }}
        />
        <Typography
          sx={{
            color: `${textColor}`,
            fontSize: "18px",
            fontWeight: fontWeight,
          }}
        >
          {status}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {amount}
      </Typography>
    </Box>
  );
};

const GenerateReportDialog = ({ open, setOpen }) => {
  const [fromDate, setFrom] = React.useState(null);
  const [toDate, setTo] = React.useState(null);
  const [report, setReport] = React.useState(null);
  const [value, setValue] = React.useState("1");

  const { user } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { mutate, isLoading, error, reset, data, isSuccess } = useMutation(
    ({ from, to }) => {
      return axios.post(
        `/system/trips/status-report`,
        {
          from,
          to,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    }
  );
  React.useEffect(() => {
    if (isSuccess) {
      setReport(data);
    }

    if (!open) {
      reset();
      setFrom(null);
      setTo(null);
    }
  }, [data, open]);

  React.useEffect(() => {
    setFrom(null);
    setTo(null);
  }, [value]);
  let oneDayHour;
  React.useEffect(() => {
    if (value === "1") {
      oneDayHour = moment(fromDate).add(1, "day");
      // .add(59, "minutes")
      // .add(59, "seconds");
    }
  }, [fromDate, value]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // pageStyle: "@page { size: 5.5in 5in ; backgroundColor:red}",
    // onAfterPrint: () => setOpen(false),
    removeAfterPrint: true,
    documentTitle: `Trip report  from ${moment(fromDate).format(
      "ll"
    )} to ${moment(toDate).add(23, "hour").add(59, "minute").format("ll")}`,
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          boxShadow: "0px 1px 7px #0000001A",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {isLoading
            ? "Generating report"
            : data
            ? "Generated report"
            : " Generate Report"}
        </Typography>
        {data ? (
          <Tooltip title="Close Modal" arrow={true} enterDelay={50}>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ color: "#000", mr: -12 }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        ) : null}
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          my: 1,
        }}
        ref={componentRef}
      >
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{
                m: 5,
                color: "#000000",
              }}
            />
          </Box>
        ) : isSuccess ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "80%",
              mt: 2,
            }}
          >
            {value === "1" ? (
              <Typography>
                Trips on{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    alignItems: "flex-start",
                    textAlign: "start",
                  }}
                >
                  {moment(fromDate).format("ll")}
                </span>
              </Typography>
            ) : (
              <Typography>
                Trips between{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    alignItems: "flex-start",
                    textAlign: "start",
                  }}
                >
                  {moment(fromDate).format("ll")} -{" "}
                  {moment(toDate)
                    .add(23, "hours")
                    .add(59, "minutes")
                    .format("ll")}{" "}
                </span>
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
                backgroundColor: "#FAFAFA",
                width: "100%",
                py: 2,
              }}
            >
              <TripsCount
                status={"Finished"}
                amount={report?.data?.data?.ended}
                color={"#18D212"}
              />
              <TripsCount
                status={"Ongoing"}
                amount={report?.data?.data?.started}
                color={"#FFDC00"}
              />

              <TripsCount
                status={"Created"}
                amount={report?.data?.data?.created}
                color={"black"}
              />

              <TripsCount
                status={"Cancelled"}
                amount={report?.data?.data?.cancelled}
                color={"#F51212"}
              />
              <TripsCount
                status={"TOTAL"}
                amount={report?.data?.data?.total}
                fontWeight={900}
                textColor={"black"}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} variant="fullWidth">
                  <Tab
                    icon={<DateRangeOutlined />}
                    iconPosition="start"
                    label="One day report"
                    value="1"
                  />
                  <Tab
                    icon={<DateRangeOutlined />}
                    iconPosition="start"
                    label="Multiple day report"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ m: 8 }}>
                <Typography
                  sx={{
                    fontSize: "17px",
                  }}
                >
                  Select a date to generate one day report
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      mb: 4,
                      mt: 3,
                    }}
                  >
                    <DatePicker
                      fullWidth={true}
                      disableFuture={true}
                      label={
                        <Typography
                          sx={{
                            fontWeight: 900,
                            ml: 2,
                          }}
                        >
                          Date
                        </Typography>
                      }
                      value={fromDate}
                      onChange={(newValue) => {
                        setFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </LocalizationProvider>
              </TabPanel>
              <TabPanel
                value="2"
                sx={{
                  m: 8,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                  }}
                >
                  Choose date and generate report
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      mb: 4,
                      mt: 3,
                    }}
                  >
                    <DatePicker
                      fullWidth={true}
                      disableFuture={true}
                      label={
                        <Typography
                          sx={{
                            fontWeight: 900,
                            ml: 2,
                          }}
                        >
                          From
                        </Typography>
                      }
                      value={fromDate}
                      onChange={(newValue) => {
                        setFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      fullWidth={true}
                      disableFuture={true}
                      minDate={new Date(fromDate) || new Date()}
                      label={
                        <Typography
                          sx={{
                            fontWeight: 900,
                            ml: 2,
                          }}
                        >
                          To
                        </Typography>
                      }
                      value={toDate}
                      onChange={(newValue) => {
                        setTo(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </LocalizationProvider>
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </DialogContent>
      {data ? (
        <DialogActions
          sx={{
            backgroundColor: "#FAFAFA",
            py: 3,
            px: 4,
            display: "flex",
            justifyContent: "flex-end",
            boxShadow: "0px 1px 7px #0000001A",
            borderRadius: "6px",
          }}
        >
          <Button
            color="inherit"
            onClick={() => reset()}
            sx={{
              width: 120,
              fontWeight: "bold",
              background: "#DFDFDF 0% 0% no-repeat padding-box",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
          >
            back
          </Button>
          <Box sx={{ px: 1 }} />
          <Button
            disabled={isLoading}
            variant="contained"
            sx={{
              width: 120,
              fontWeight: "bold",
              color: "#fff",
              boxShadow: " 0px 2px 5px #00000033",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </DialogActions>
      ) : (
        <DialogActions
          sx={{
            backgroundColor: "#FAFAFA",
            py: 3,
            px: 4,
            display: "flex",
            justifyContent: "flex-end",
            boxShadow: "0px 1px 7px #0000001A",
            borderRadius: "6px",
          }}
        >
          <Button
            color="inherit"
            onClick={() => setOpen(false)}
            sx={{
              width: 120,
              fontWeight: "bold",
              background: "#DFDFDF 0% 0% no-repeat padding-box",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Box sx={{ px: 1 }} />
          <Button
            disabled={isLoading || !fromDate}
            variant="contained"
            sx={{
              width: 120,
              fontWeight: "bold",
              color: "#fff",
              boxShadow: " 0px 2px 5px #00000033",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              mutate({
                from: moment(fromDate).toISOString(true),
                to:
                  value === "1"
                    ? moment(oneDayHour).toISOString(true)
                    : moment(toDate).add(1, "day").toISOString(true),
              });
              console.log(data?.data);
            }}
          >
            Generate
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Data Found!</Typography>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomToolBar(props) {
  const { user } = useAuth();
  const [dataToExport, setDataToExport] = useState([]);

  const {
    isLoading,
    isSuccess,
    isError,
    data: exportData,
    error,
    refetch,
  } = useQuery("dataToExport", () => {
    return axios.get(
      "/system/trips/all",
      {
        params: {
          // page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      },
      {
        // refetchOnWindowFocus: true,
        enabled: access(["get street pickup trips"]), // turned off by default, manual refetch is needed
      }
    );
  });

  useEffect(() => {
    setDataToExport(exportData?.data?.data);
    // console.log("export data", exportData?.data?.data);
  }, []);

  // console.log("export data ", dataToExport);

  let headers = [
    { label: "Passenger number", key: "passenger_phone_number" },
    { label: "start time", key: "start_time" },
    { label: "end time", key: "end_time" },
    { label: "distance", key: "distance" },
    { label: "estimate distance", key: "bare_distance" },
    { label: "waiting time ", key: "waiting_time" },
    { label: "start point", key: "start_point" },
    { label: "end point", key: "end_point" },
    { label: "start point name ", key: "start_point_name" },
    { label: "end point name ", key: "end_point_name" },
    { label: "estimated cost ", key: "estimated_cost" },
    { label: " Total cost ", key: "cost" },
    { label: "Poly line", key: "poly_line" },
    { label: "waiting time ", key: "waiting_cost" },
    { label: "created at ", key: "created_at" },
    { label: "updated at ", key: "updated_at" },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2,
        mx: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
        }}
      >
        <GridToolbarColumnsButton sx={{ mx: 1, my: 1 }} />
        <GridToolbarFilterButton sx={{ mx: 1, my: 1 }} />
        <GridToolbarDensitySelector sx={{ mx: 1, my: 1 }} />
        <CSVLink
          style={{
            textDecoration: "none",
            alignSelf: "center",
            marginLeft: "10px",
          }}
          data={exportData?.data?.data || []}
          filename="Street pickup data.csv"
          headers={headers}
        >
          {!isLoading ? (
            <GridToolbarExport
              printOptions={{
                hideFooter: true,
                hideToolbar: true,
              }}
              disabled={isLoading}
              sx={{
                p: 2,
              }}
            />
          ) : null}
        </CSVLink>
      </Box>
      <Box>
        <Button
          sx={{
            mr: 3,
            py: 1,
            px: 2,
            color: "#fff",
            backgroundColor: "primary.main",
            borderRadius: "5px",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={props.generateDialog}
        >
          Generate Report
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search "
          placeholder="search"
          size="small"
          InputProps={{
            endAdornment: props.value ? (
              <Clear
                title="Search"
                aria-label="Search"
                style={{
                  visibility: props.value ? "visible" : "hidden",
                  cursor: "pointer",
                }}
                onClick={props.clearSearch}
              />
            ) : (
              <Search title="Search" aria-label="Search" />
            ),
          }}
        />
      </Box>
    </Box>
  );
}

const StreetPickup = () => {
  const { user, permissions } = useAuth();
  const [filterValue, setFilterValue] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    { field: "created_at", sort: "desc" },
  ]);
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [opendialog, setOpenDialog] = React.useState(false);

  const navigate = useNavigate();

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["street-pickup", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get("/system/trips/all", {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
        params: {
          page: Number(page) + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    operator: "is",
                    value: JSON.stringify({
                      value: searchText,
                      columns: [
                        "passenger_phone_number",
                        "cost",
                        "distance",
                        "estimated_cost",
                        "driver_phone",
                      ],
                    }),
                  },
                ]
              : []),
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
      });
    },
    {
      refetchOnWindowFocus: access(["get street pickup trips"]),
      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  useEffect(() => {
    if (access(["get street pickup trips"])) {
      refetch();
    }
  }, [page, sortModel, filterValue, searchText, perPage]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
  };
  const onFilterChange = React.useCallback((params) => {
    const filter = params.items?.[0];
    if (params.items[0]?.value) {
      setFilterValue(filter);
    } else {
      setFilterValue({});
    }
  }, []);

  const handlePageChange = (page) => {
    if (page <= total) {
      return setPage(page);
    }
    return;
  };

  return (
    <>
      <GenerateReportDialog open={opendialog} setOpen={setOpenDialog} />
      <Container
        component="main"
        disableGutters={true}
        maxWidth="xl"
        sx={{ backgroundColor: "#fff", minHeight: "100%" }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
              <DataGrid
                autoHeight={true}
                // disableColumnMenu={true}
                // density={"comfortable"}
                columns={[
                  {
                    field: "id",
                    headerName: "ID",
                    width: "200",
                    // filterable: false,
                    sortable: false,
                    hide: true,
                  },
                  {
                    width: 210,
                    field: "start_point_name",
                    headerName: "From",
                    hide: true,
                  },

                  {
                    width: 210,
                    field: "end_point_name",
                    headerName: "To",
                    hide: true,
                  },
                  {
                    width: 160,
                    field: "created_at",
                    headerName: "Create at",
                    type: "date",
                    valueGetter: (params) =>
                      moment(params?.row?.created_at).format("lll"),
                  },
                  {
                    width: 160,
                    field: "start_time",
                    headerName: "Start Time",
                    sortable: false,

                    type: "date",
                    valueGetter: (params) =>
                      moment(params?.row?.start_time).format("lll"),
                  },
                  {
                    width: 160,
                    field: "end_time",
                    headerName: "End Time",
                    type: "date",
                    valueGetter: (params) =>
                      moment(params?.row?.end_time).format("lll"),
                  },

                  {
                    width: 120,
                    field: "passenger_phone_number",
                    headerName: "Passenger No ",
                  },

                  {
                    width: 120,
                    field: "driver_phone",
                    headerName: "Driver No ",
                  },

                  {
                    width: 130,
                    field: "distance",
                    headerName: "Distance",
                    type: "number",
                    align: "center",
                    headerAlign: "center",

                    valueGetter: (params) => {
                      if (params?.row?.distance > 0) {
                        return (
                          roundToTwoDecimalPlaces(params?.row?.distance) + " KM"
                        );
                      }
                    },
                  },
                  {
                    width: 120,
                    field: "estimated_cost",
                    headerName: "Estimated Price",
                    type: "number",
                    align: "center",
                    headerAlign: "center",

                    valueGetter: (params) => {
                      if (params?.row?.estimated_cost > 0) {
                        return (
                          roundToTwoDecimalPlaces(params?.row?.estimated_cost) +
                          " ETB"
                        );
                      }
                    },
                  },

                  {
                    width: 120,
                    field: "cost",
                    headerName: "Price",
                    type: "number",
                    align: "center",
                    headerAlign: "center",
                  },

                  {
                    width: 160,
                    field: "status",
                    headerName: "Status",
                    align: "center",
                    headerAlign: "center",

                    sortable: false,
                    renderCell: (params) => {
                      return (
                        <Box
                          sx={{
                            width: "130px",
                          }}
                        >
                          <Alert
                            severity="info"
                            icon={false}
                            sx={{
                              justifyContent: "center",
                              borderRadius: "10px",
                              textTransform: "capitalize",
                              backgroundColor: `${
                                params?.value === "STARTED"
                                  ? "#F5F5F5"
                                  : params?.value === "ENDED"
                                  ? "#E5FFEA"
                                  : params?.value === "CANCELLED"
                                  ? "#FFE5E5"
                                  : params?.value === "CREATED"
                                  ? "#EFEFEF"
                                  : "white"
                              }`,
                              color: `${
                                params?.value === "STARTED"
                                  ? "#FFDC00"
                                  : params?.value === "ENDED"
                                  ? "#16C138"
                                  : params?.value === "CANCELLED"
                                  ? "#E11212"
                                  : params?.value === "CREATED"
                                  ? "#000000"
                                  : "text.primary"
                              }`,
                              fontWeight: "bold",
                            }}
                          >
                            {params?.value.toLowerCase()}
                          </Alert>
                        </Box>
                      );
                    },
                  },
                  {
                    field: "action",
                    headerName: "Actions",
                    width: 120,
                    filterable: false,
                    sortable: false,
                    type: "actions",

                    renderCell: (params) => {
                      return (
                        <Box>
                          <RemoveRedEye
                            sx={{
                              ml: 5,
                              cursor: "pointer",
                              color: "black ",
                              "&:hover": {
                                color: "primary.main",
                              },
                            }}
                            onClick={() => {
                              navigate(
                                `${params?.row?.id}/tripDetails`,

                                {
                                  state: {
                                    passenger_phone_number:
                                      params?.row?.passenger_phone_number,
                                  },
                                }
                              );
                            }}
                          />
                        </Box>
                      );
                    },
                  },
                ]}
                rows={data?.data?.data || []}
                rowCount={total}
                page={page}
                pageSize={perPage}
                loading={isLoading}
                rowsPerPageOptions={[5, 10, 15, 20, 30, 40, 50, 100]}
                paginationMode="server"
                onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
                onPageChange={(newPage) => handlePageChange(newPage)}
                sortingMode="server"
                sortModel={sortModel}
                sortingOrder={["desc", "asc"]}
                onSortModelChange={(newModel) => setSortModel(newModel)}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                components={{
                  Toolbar: CustomToolBar,
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                    generateDialog: () => {
                      setOpenDialog(true);
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default StreetPickup;
