import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { isError, useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const ReimburseReportDialog = ({ open, setOpen, accountID, refetch }) => {
  const [amount, setAmount] = React.useState();
  const [remark, setRemark] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const { mutate, isError, isLoading, error, data, isSuccess } = useMutation(
    ({ amount, remark }) => {
      return axios.post(
        `/system/account/reimburse`,
        {
          account_id: accountID,
          amount,
          remark,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    }
  );

  React.useEffect(() => {
    setAmount(null);
    setRemark(null);
  }, [open]);

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Account succesfully reimbursed", {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch() && setOpen(false);
    }
    if (isError) {
      enqueueSnackbar("Error happened reimbursing", {
        variant: "error",
        autoHideDuration: 2000,
      });
      setOpen(false);
    }
  }, [isSuccess, isError]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          boxShadow: "0px 1px 7px #0000001A",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          Reimbursement
        </Typography>

        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            color: "#000",
            mr: -14,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          my: 2,
        }}
      >
        <Typography
          sx={{
            my: 3,
            textAlign: "center",
            color: "text.secondary",
            fontWeight: "bold",
          }}
        >
          Reimburse by entering amount and remark
        </Typography>
        <TextField
          id="amount"
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          required
          sx={{
            my: 2,
            width: "50%",
            textAlign: "center",
          }}
        />
        <TextField
          required
          id="remark"
          label="Remarks"
          value={remark}
          onChange={(e) => {
            setRemark(e.target.value);
          }}
          sx={{
            my: 2,
            width: "50%",
            textAlign: "center",
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          boxShadow: "0px 1px 7px #0000001A",
          borderRadius: "6px",
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpen(false)}
          sx={{
            width: 120,
            fontWeight: "bold",
            background: "#DFDFDF 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            textTransform: "capitalize",
          }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          variant="contained"
          type="submit"
          disabled={!amount || !remark}
          sx={{
            width: 120,
            fontWeight: "bold",
            color: "#fff",
            boxShadow: " 0px 2px 5px #00000033",
            borderRadius: "8px",
            textTransform: "capitalize",
          }}
          onClick={() => {
            mutate({
              accountID,
              amount,
              remark,
            });
          }}
        >
          Reimburse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function QuickSearchToolbar(props) {
  const params = useParams();
  const { user, permissions } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    `/system/accounts/${params?.accountId}/transactionsExport`,
    () => {
      return axios.get(`/system/accounts/${params?.accountId}/transactions`, {
        params: {
          page: -1,
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button
          sx={{
            mr: 3,
            py: 1,
            px: 2,
            color: "#fff",
            backgroundColor: "primary.main",
            borderRadius: "5px",
            textTransform: "capitalize",
            ":hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={props.reimburseDialog}
        >
          REIMBURSE
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function PersonalTransaction() {
  const params = useParams();
  const { user, permissions } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "created_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opendialog, setOpenDialog] = React.useState(false);

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    [
      `/system/accounts/${params?.accountId}/transactions`,
      page,
      sortModel,
      filterValue,
      searchText,
      perPage,
    ],
    () => {
      return axios.get(`/system/accounts/${params?.accountId}/transactions`, {
        params: {
          page: +page + 1,
          per_page: perPage,
          sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
          filter: JSON.stringify([
            ...(searchText
              ? [
                  {
                    columnField: "q",
                    value: JSON.stringify({
                      value: searchText,
                      columns: ["note"],
                    }),
                  },
                ]
              : []),

            // {
            //   columnField: "group_id",
            //   operatorValue: "=",
            //   value: params?.accountId,
            // },
            ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
          ]),
        },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    }
  );

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.errorMessage ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <>
      <ReimburseReportDialog
        open={opendialog}
        setOpen={setOpenDialog}
        accountID={params?.accountId}
        refetch={refetch}
      />
      <Container
        component="main"
        disableGutters={true}
        maxWidth="xl"
        sx={{ backgroundColor: "#fff", minHeight: "100%" }}
      >
        {/* {JSON.stringify(data?.data?.data, null, 2)} */}
        <Box sx={{ height: 500, width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
              <DataGrid
                autoHeight
                disableColumnMenu={true}
                columns={[
                  { field: "id", hide: true },
                  {
                    width: 160,
                    field: "created_at",
                    headerName: "Time",
                    type: "date",
                    valueGetter: (params) =>
                      moment(params?.row?.created_at).format("lll"),
                  },
                  {
                    width: 120,
                    field: "amount",
                    type: "number",
                    headerName: "Amount",
                  },
                  {
                    field: "tip",
                    type: "number",
                    headerName: "Tip",
                  },
                  {
                    width: 160,
                    field: "type",
                    headerName: "Type",
                  },
                  {
                    width: 260,
                    field: "from",
                    headerName: "From",
                    valueGetter: (params) =>
                      `${params?.row?.from?.name
                        ?.split(" ")
                        ?.splice(0, 2)
                        ?.join(" ")} - ${params?.row?.from?.phone}`,
                  },
                  {
                    width: 260,
                    field: "to",
                    headerName: "To",
                    valueGetter: (params) =>
                      `${params?.row?.to?.name
                        ?.split(" ")
                        ?.splice(0, 2)
                        ?.join(" ")} - ${params?.row?.to?.phone}`,
                  },
                  {
                    width: 280,
                    field: "note",
                    headerName: "Note",
                  },
                ]}
                rows={data?.data?.data || []}
                components={{
                  Toolbar: QuickSearchToolbar,
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pagination
                page={page}
                pageSize={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                rowCount={total}
                paginationMode="server"
                onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
                onPageChange={(newPage) => setPage(newPage)}
                loading={isLoading}
                sortingMode="server"
                sortModel={sortModel}
                sortingOrder={["desc", "asc"]}
                onSortModelChange={(newModel) => setSortModel(newModel)}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                    add: () => setOpenAdd(true),
                    reimburseDialog: () => {
                      setOpenDialog(true);
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
